/**
 * Enumeration des routes disponibles ici
 */
export enum RouteEnum {
    HOME='/',
    LOADED='/loading',
    NOT_ACCESS='/no-access',
    TERMS_VALIDATE = '/terms/validate',
    MAINTENANCE = '/maintenance',

    // Routes utilisateur
    BOT='/bot',
    DASHBOARD='/dashboard',
    DOCUMENTS='/documents',
    E_LEARNING='/formations-e-learning',
    E_LEARNING_UPDATE_SCORE='/formations-e-learning/score/:id/:user/edit',
    E_LEARNING_DETAIL='/detailformations/:id',
    MY_PROFILE='/pour-votre-profil',
    PRESENTIEL='/formation-presentiel',
    PRESENTIEL_DETAIL='/detailformation/:id',
    THEMATIC_LIST='/thematic/:category/:id',
    WEBINAR_DETAIL='/webinars/:id',
    PROFILE='/profile',
    STARTED='/formations-en-cours',
    SEARCH='/search',
    /* ----------------- *
    *        ADMIN       *
    * ------------------ */
    ADMIN_DASHBOARD='/admin',
    // Détail utilisateur
    ADMIN_ELEARNER_SHOW='/admin/elearner/:id',
    ADMIN_LEARNER_SHOW='/admin/learner/:id',
    // Formations e-learning
    ADMIN_E_LEARNING='/admin/formation-e-learning',
    ADMIN_E_LEARNING_SHOW='/admin/formation-e-learning/:id',
    ADMIN_E_LEARNING_ADD='/admin/formation-e-learning/create',
    ADMIN_E_LEARNING_ADD_STEP_2='/admin/creer-chapitre-e-learning/:id',
    ADMIN_E_LEARNING_ADD_STEP_2_5='/admin/create-unite-de-formation/:id',
    ADMIN_E_LEARNING_ADD_STEP_3='/admin/add-skills-elearning/:id',
    ADMIN_E_LEARNING_EDIT='/admin/formation-e-learning/:id/edit',
    ADMIN_E_LEARNING_LEARNER='/admin/formation-e-learning/apprenants',
    // Formations présentiels
    ADMIN_ON_SITE='/admin/formation-presentielle',
    ADMIN_ON_SITE_SHOW='/admin/formation-presentielle/:id',
    ADMIN_ON_SITE_ADD='/admin/formation-presentielle/create',
    ADMIN_ON_SITE_ADD_STEP_2='/admin/create-session-formation/:id',
    ADMIN_ON_SITE_ADD_STEP_3='/admin/createSession-etape3/:id',
    ADMIN_ON_SITE_EDIT='/admin/formation-presentielle/:id/edit',
    ADMIN_SESSION='/admin/session',
    ADMIN_SESSION_ADD='/admin/session/create',
    ADMIN_SESSION_ADD_TRAINING='/admin/session/create/:training',
    ADMIN_SESSION_SHOW='/admin/session/:id',
    ADMIN_SESSION_EDIT='/admin/session/:id/edit',
    ADMIN_ON_SITE_PARTICIPANT='/admin/formation-presentielle/participants',
    ADMIN_ON_SITE_PARTICIPANT_MANAGE='/admin/formation-presentielle/participants/manage/:idEntity',
    ADMIN_TRAINER='/admin/formateurs',
    ADMIN_TRAINER_ADD='/admin/formateurs/add',
    ADMIN_TRAINER_SHOW='/admin/formateurs/:id',
    ADMIN_TRAINER_EDIT='/admin/formateurs/:id/edit',
    ADMIN_PROVIDER='/admin/prestataires',
    ADMIN_PROVIDER_ADD='/admin/prestataires/add',
    ADMIN_PROVIDER_SHOW='/admin/prestataires/:id',
    ADMIN_PROVIDER_EDIT='/admin/prestataires/:id/edit',
    ADMIN_TRAINING_PLACE='/admin/lieu-formation',
    ADMIN_TRAINING_PLACE_ADD='/admin/lieu-formation/add',
    ADMIN_TRAINING_PLACE_SHOW='/admin/lieu-formation/:id',
    ADMIN_TRAINING_PLACE_EDIT='/admin/lieu-formation/:id/edit',
    ADMIN_TRAINING_REQUEST='/admin/besoins-formation',
    ADMIN_CATEGORY='/admin/categories',
    ADMIN_CATEGORY_ADD='/admin/categories/add',
    ADMIN_CATEGORY_EDIT='/admin/categories/:id/edit',
    ADMIN_CATEGORY_SHOW='/admin/categories/:id',
    ADMIN_THEMATIC='/admin/thematiques',
    ADMIN_THEMATIC_ADD='/admin/thematiques/add',
    ADMIN_THEMATIC_SHOW='/admin/thematiques/:id',
    ADMIN_THEMATIC_EDIT='/admin/thematiques/:id/edit',
    ADMIN_SKILL='/admin/compétences',
    ADMIN_SKILL_ADD='/admin/compétences/add',
    ADMIN_SKILL_SHOW='/admin/compétences/:id',
    ADMIN_SKILL_EDIT='/admin/compétences/:id/edit',
    ADMIN_WEBINAR='/admin/webinaires',
    ADMIN_WEBINAR_ADD='/admin/webinaires/add',
    ADMIN_WEBINAR_SHOW='/admin/webinaires/:id',
    ADMIN_WEBINAR_EDIT='/admin/webinaires/:id/edit',
    ADMIN_WEBINAR_PARTICIPANT_MANAGE='/admin/webinaires/participants/manage/:idEntity',
    ADMIN_SETTINGS='/admin/settings/edit',
    ADMIN_USERS='/admin/users',
}
